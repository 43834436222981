import React from "react";
import {
  Control,
  FieldArrayWithId,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import { ComonObjectType } from "ts/types";
import JobEntry from "./JobEntry";

interface StepProps {
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  clearErrors: UseFormClearErrors<any>;
  register: UseFormRegister<any>;
  idKey: string;
  errorKey: ComonObjectType | undefined;
  jobsFields?: FieldArrayWithId<any, "employmentDetail", "id">[];
}

const STEP_KEY = "employmentDetail";

const MainEmploymentJobs: React.FC<StepProps> = ({
  control,
  setValue,
  clearErrors,
  register,
  idKey,
  errorKey,
  jobsFields,
}) => {
  if (!jobsFields) {
    return <></>;
  }

  return (
    <div className="flex flex-col space-y-4">
      {jobsFields.map((item, k) => {
        return (
          <div key={item.id} className="flex flex-col space-y-4">
            <label className="block text-base font-semibold">Job {k + 1}</label>
            <JobEntry
              jobKey={`${idKey}.${STEP_KEY}.${k}`}
              control={control}
              setValue={setValue}
              clearErrors={clearErrors}
              register={register}
              errorKey={errorKey?.[STEP_KEY]?.[k]}
            />
          </div>
        );
      })}
    </div>
  );
};

export default MainEmploymentJobs;
