import {
  APPLICATION_COMPLETE_KEY,
  APPLICATION_IN_PROGRESS_KEY,
} from "utils/consts";
import APPLICANT_DETAILS_DEFAULTS from "./steps/ApplicantDetails/defaultData";
import APPLICANT_EMPLOYMENT_DEFAULTS from "./steps/ApplicantEmployment/defaultData";
import APPLICANT_EXPENDITURE_DEFAULTS from "./steps/ApplicantExpenditure/defaultData";
import LOAN_DEFAULTS from "./steps/Loan/defaultData";
import START_DEFAULTS from "./steps/Start/defaultData";

export const DEFAULT_FORM_DATA = {
  ...START_DEFAULTS,
  ...{
    applicants: [
      {
        details: {
          ...APPLICANT_DETAILS_DEFAULTS,
        },
        employments: {
          ...APPLICANT_EMPLOYMENT_DEFAULTS,
        },
        expenditures: {
          ...APPLICANT_EXPENDITURE_DEFAULTS,
        },
      },
    ],
  },
  ...LOAN_DEFAULTS,
};

export const DEFAULT_FORM_STATUS = {
  start: APPLICATION_IN_PROGRESS_KEY,
  applicants: APPLICATION_IN_PROGRESS_KEY,
  employments: APPLICATION_IN_PROGRESS_KEY,
  expenditures: APPLICATION_IN_PROGRESS_KEY,
  loan: APPLICATION_IN_PROGRESS_KEY,
};

export const COMPLETE_FORM_STATUS = {
  start: APPLICATION_COMPLETE_KEY,
  applicants: APPLICATION_COMPLETE_KEY,
  employments: APPLICATION_COMPLETE_KEY,
  expenditures: APPLICATION_COMPLETE_KEY,
  loan: APPLICATION_COMPLETE_KEY,
};
