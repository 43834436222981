import { CaseType } from "pages/Illustration/types";
import { Link } from "react-router-dom";
import { APP_URLS } from "settings";
import { ApplicationType } from "../types";

interface SidebarProps {
  application?: ApplicationType | null;
  thisCase?: CaseType | null;
}

const DocsSidebar: React.FC<SidebarProps> = ({ application, thisCase }) => {
  if (application) {
    return (
      <div>
        {application.reference && application.applicationDate && (
          <>
            <span className="mb-1 block">
              <span className="font-semibold">Reference:</span>
              <br />
              {application.reference}
            </span>
            <span className="mb-1 block">
              <span className="font-semibold">Date submitted:</span>
              <br />
              {new Date(application.applicationDate).toLocaleDateString(
                "en-GB",
              )}
            </span>
          </>
        )}
        {thisCase?.reference && (
          <span className="block">
            <span className="font-semibold">Case:</span>
            <br />
            <Link
              to={`${APP_URLS.DASHBOARD}cases/${thisCase.uuid}`}
              className="underline"
            >
              {thisCase.reference}
            </Link>
          </span>
        )}
      </div>
    );
  }

  return <></>;
};

export default DocsSidebar;
