import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";
import { QUERY_DIP_COMPLETE } from "api/queries/dip";
import Loading from "components/Loading";
import PageContent from "components/Page/Content";
import { DipType } from "ts/interfaces/common/dip";
import DipDecisionFail from "./components/DipDecisionFail";
import DipDecisionPass from "./components/DipDecisionPass";

const POLL_INTERVAL = 5000; // 5 seconds in milliseconds
const POLL_TIMEOUT = 120000; // 2 minutes in milliseconds
const DIP_DECISION_PENDING = "PENDING";

const DipComplete: React.FC = () => {
  const { dipId: urlDipId } = useParams<{
    dipId: string;
  }>();

  const [dip, setDip] = useState<DipType | null>(null);
  const [pollingTimeout, setPollingTimeout] = useState(false);
  const [thisTimer, setThisTimer] = useState<any>(null);

  const [
    getDip,
    { data: backendDip, error: getDipError, startPolling, stopPolling },
  ] = useLazyQuery(QUERY_DIP_COMPLETE, { fetchPolicy: "no-cache" });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPollingTimeout(true);
      stopPolling();
    }, POLL_TIMEOUT);
    setThisTimer(timeout);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (urlDipId) {
      window.scrollTo(0, 0);
      getDip({
        variables: {
          uuid: urlDipId,
        },
      });
      startPolling(POLL_INTERVAL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlDipId, getDip]);

  useEffect(() => {
    if (!backendDip) {
      return;
    }
    setDip(backendDip?.dip);
  }, [backendDip, setDip]);

  useEffect(() => {
    if (!dip?.decision) {
      return;
    }
    if (dip?.decision !== DIP_DECISION_PENDING) {
      stopPolling();
      clearTimeout(thisTimer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dip, stopPolling]);

  const renderDipDecision = () => {
    if (getDipError) {
      return (
        <>
          <p>
            Sorry, there has been an error submitting your Decision in
            Principle.
          </p>
          <p className="mt-2">Please contact us to discuss this further.</p>
        </>
      );
    }

    if (pollingTimeout) {
      return <DipDecisionFail dip={dip} />;
    }

    if (!dip || dip?.decision === DIP_DECISION_PENDING) {
      return (
        <>
          <Loading />
          <p className="mt-4 text-center">
            Submitting Decision in Principle...
          </p>
        </>
      );
    }

    if (dip && dip.success) {
      return <DipDecisionPass dip={dip} />;
    } else {
      return <DipDecisionFail dip={dip} />;
    }
  };

  return (
    <PageContent title="Decision in Principle (DIP)">
      <div className="mx-auto max-w-3xl space-y-5">
        <div className="rounded-lg border border-gray-200 bg-white p-6 md:p-8">
          {renderDipDecision()}
        </div>
      </div>
    </PageContent>
  );
};

export default DipComplete;
