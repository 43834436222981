import { useQuery } from "@apollo/client";
import { useState } from "react";

import { QUERY_APPLICATIONS } from "api/queries/applications";
import ApplicationList from "components/Application/List";
import Pagination, { getPaginationOffset } from "components/Pagination";
import { APPLICATION_IN_PROGRESS_KEY } from "utils/consts";

const ITEMS_PER_PAGE = 10;

export default function DashboardApplications() {
  const [currentPage, setCurrentPage] = useState(1);

  const { data } = useQuery(QUERY_APPLICATIONS, {
    variables: {
      status: APPLICATION_IN_PROGRESS_KEY,
      selectLatest: true,
      offset: getPaginationOffset(currentPage, ITEMS_PER_PAGE),
    },
  });

  if (data && data.applications) {
    return (
      <div className="mt-8">
        <ApplicationList
          title="Applications waiting to be submitted"
          showCase
          showApplicants
          showDuplicate={false}
          showCreate={false}
          applications={data.applications.results}
        />
        <Pagination
          data={data.applications}
          itemsPerPage={ITEMS_PER_PAGE}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    );
  }

  return <></>;
}
