export const DEFAULT_EMPLOYMENT_OTHER_SOURCES_DATA = {
  source: "",
  annualGrossIncome: "",
};

export const DEFAULT_EMPLOYMENT_DETAIL_DATA = {
  employmentStatus: "",
  employmentType: "",
  occupation: "",
  employerName: "",
  dailyRate: "",
  treatedAsEmployedForTax: undefined,
  startDate: "",
  endDate: "",
  annualBasicSalary: "",
  annualBonusGross: "",
  annualOvertimeGross: "",
  annualCommissionGross: "",
  annualLocationOrCarAllowanceGross: "",
  businessName: "",
  percentBusinessOwned: "",
  projectedGrossProfitCurrentTradingYear: "",
  latestYearNetProfit: "",
  previousYearNetProfit: "",
  shareholdingPercentInCompany: "",
  salaryFromLatestFinalisedYear: "",
  salaryFromPreviousYear: "",
  currentYearProjectedDividends: "",
  dividendFromLatestFinalisedYear: "",
  dividendFromPreviousYear: "",
  selfEmployedAccountant: {
    address: "",
    buildingName: "",
    buildingNumber: "",
    subBuildingName: "",
    subBuildingNumber: "",
    thoroughfare: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    townOrCity: "",
    county: "",
    postcode: "",
    firmName: "",
    phoneNumber: "",
    emailAddress: "",
  },
};

export const DEFAULT_EMPLOYMENT_DATA = {
  isRetired: undefined,
  employedInUk: undefined,
  numberOfJobs: undefined,
  awareOfAnyChangesToIncome: undefined,
  incomeChangesDetails: "",
  hasOtherSourcesOfIncome: undefined,
  anticipatedRetirementAge: undefined,
  mortgageTermBeyondRetirementAge: undefined,
  retirementIncomeSource: "",
  anticipatedCurrentRetirementAnnualAmount: undefined,
  employmentDetail: [],
  employmentOtherIncomeSources: [],
};
