import { useLazyQuery } from "@apollo/client";
import { QUERY_ILLUSTRATION_COMPLETE } from "api/queries/illustration";
import FormTitle from "components/Form/Title";
import Loading from "components/Loading";
import PageContent from "components/Page/Content";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { APP_URLS } from "settings";
import useApplicationStore from "store/Application";

export default function IllustrationComplete() {
  let navigate = useNavigate();

  const { illustrationId: urlIllustrationId } = useParams<{
    illustrationId: string;
  }>();

  const [illustration, setIllustration] = useState<any | null>(null);

  const { setAppllicationCase, resetApplication } = useApplicationStore();

  const [
    getIllustration,
    {
      data: backendIllustration,
      loading: getIllustrationLoading,
      error: getIllustrationError,
    },
  ] = useLazyQuery(QUERY_ILLUSTRATION_COMPLETE, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (urlIllustrationId) {
      getIllustration({
        variables: {
          uuid: urlIllustrationId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlIllustrationId, getIllustration]);

  useEffect(() => {
    if (!backendIllustration) {
      return;
    }
    setIllustration(backendIllustration?.illustration);
  }, [backendIllustration, setIllustration]);

  const startNewApplication = () => {
    resetApplication();

    if (illustration?.case) {
      setAppllicationCase({
        reference: illustration.case.reference,
        uuid: illustration.case.uuid,
      });
    }
    navigate(APP_URLS.APPLICATION_FORM);
  };

  return (
    <PageContent title="Mortgage Illustration (ESIS)">
      <div className="mx-auto max-w-3xl space-y-5">
        <div className="rounded-lg border border-gray-200 bg-white p-6 md:p-8">
          {getIllustrationLoading && (
            <>
              <Loading />
              <p className="mt-4 text-center">Submitting Illustration...</p>
            </>
          )}

          {getIllustrationError && (
            <>
              <p>
                Sorry, there has been an error submitting your Illustration.
              </p>
              <p className="mt-2">Please contact us to discuss this further.</p>
            </>
          )}

          {illustration && (
            <div className="space-y-4">
              <FormTitle
                left
                border
                title="The Mortgage Illustration has been generated"
                mb={false}
              />

              <div className="space-y-2">
                <p>
                  View and download the Mortgage Illustration:{" "}
                  <a
                    download
                    href={illustration?.downloadUrl}
                    className="link underline"
                  >
                    Download
                  </a>
                </p>
                <p>
                  To continue with the application process, please submit an
                  application form. You can find it{" "}
                  <button
                    onClick={startNewApplication}
                    className="link underline"
                  >
                    here
                  </button>
                  .
                </p>

                {illustration?.case?.reference && (
                  <>
                    <p>
                      You can track your Case ({illustration.case.reference})
                      attached to this Illustration{" "}
                      <Link
                        to={`${APP_URLS.DASHBOARD}cases/${illustration.case.uuid}`}
                        className="link underline"
                      >
                        here
                      </Link>
                      .
                    </p>
                  </>
                )}
                <p>
                  Please note that any quotations produced are illustrative and
                  not guaranteed.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </PageContent>
  );
}
