import React, { useEffect, useState } from "react";
import {
  Control,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { ComonObjectType } from "ts/types";

import AddressSetter from "components/Form/AddressSetter";
import FormInputCurrency from "components/Form/Input/Currency";
import FormInputDate from "components/Form/Input/Date";
import FormInputPercent from "components/Form/Input/Percent";
import FormInputRadio from "components/Form/Input/Radio";
import FormInputSelect from "components/Form/Input/Select";
import FormInputText from "components/Form/Input/Text";
import { DEFAULT_EMPLOYMENT_DETAIL_DATA } from "./defaultData";
import { LABELS } from "./labels";
import {
  ApplicantEmploymentDetailStatuses,
  ApplicantEmploymentTypeStatuses,
} from "./options";

interface JobEntryProps {
  jobKey: string;
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  clearErrors: UseFormClearErrors<any>;
  register: UseFormRegister<any>;
  errorKey: ComonObjectType | undefined;
}

const JobEntry: React.FC<JobEntryProps> = ({
  jobKey,
  control,
  setValue,
  clearErrors,
  register,
  errorKey,
}) => {
  const applicantsEmployment = useWatch({
    name: `${jobKey}`,
    control: control,
  });

  const [employmentStatus, setEmploymentStatus] = useState<string | undefined>(
    undefined,
  );
  const [employmentType, setEmploymentType] = useState<string | undefined>(
    undefined,
  );

  // Update employment status when applicantsEmployment changes
  React.useEffect(() => {
    if (!applicantsEmployment) {
      return;
    }
    if (
      employmentStatus &&
      applicantsEmployment?.employmentStatus !== employmentStatus
    ) {
      setValue(jobKey, {
        ...DEFAULT_EMPLOYMENT_DETAIL_DATA,
        ...{
          employmentStatus: applicantsEmployment.employmentStatus,
        },
      });
      setEmploymentStatus(applicantsEmployment?.employmentStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantsEmployment?.employmentStatus]);

  // Reset employmentType if change
  useEffect(() => {
    if (!applicantsEmployment) {
      return;
    }
    if (
      employmentType &&
      applicantsEmployment?.employmentType !== employmentType
    ) {
      setValue(`${jobKey}.dailyRate`, "");
      setValue(`${jobKey}.treatedAsEmployedForTax`, undefined);
      setEmploymentType(applicantsEmployment?.employmentType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantsEmployment?.employmentType]);

  return (
    <>
      <input
        {...register(`${jobKey}.uuid`, {
          value: applicantsEmployment?.uuid,
        })}
        type="hidden"
      />

      <FormInputSelect
        id={`${jobKey}.employmentStatus`}
        label={LABELS.employmentDetail.employmentStatus}
        register={register}
        initSelect
        options={ApplicantEmploymentDetailStatuses}
        requiredField={false}
        error={errorKey?.employmentStatus?.message}
      />

      {["EMPL", "DNOD"].includes(
        applicantsEmployment?.employmentStatus || "",
      ) && (
        <>
          <FormInputSelect
            id={`${jobKey}.employmentType`}
            label={LABELS.employmentDetail.employmentType}
            register={register}
            initSelect
            options={ApplicantEmploymentTypeStatuses}
            requiredField={false}
            error={errorKey?.employmentType?.message}
          />

          {["SCFT", "SCOE"].includes(
            applicantsEmployment.employmentType || "",
          ) && (
            <>
              <FormInputCurrency
                id={`${jobKey}.dailyRate`}
                label={LABELS.employmentDetail.dailyRate}
                register={register}
                requiredField={false}
                error={errorKey?.dailyRate?.message}
                setValue={setValue}
              />

              <FormInputRadio
                id={`${jobKey}.treatedAsEmployedForTax`}
                label={LABELS.employmentDetail.treatedAsEmployedForTax}
                register={register}
                requiredField={false}
                initValue={applicantsEmployment?.treatedAsEmployedForTax}
                error={errorKey?.treatedAsEmployedForTax?.message}
              />
            </>
          )}

          <FormInputText
            id={`${jobKey}.occupation`}
            label={LABELS.employmentDetail.occupation}
            register={register}
            requiredField={false}
            error={errorKey?.occupation?.message}
          />

          <FormInputText
            id={`${jobKey}.employerName`}
            label={LABELS.employmentDetail.employerName}
            register={register}
            requiredField={false}
            error={errorKey?.employerName?.message}
          />

          <FormInputDate
            id={`${jobKey}.startDate`}
            label={LABELS.employmentDetail.startDate}
            register={register}
            requiredField={false}
            error={errorKey?.startDate?.message}
          />

          <FormInputDate
            id={`${jobKey}.endDate`}
            label={LABELS.employmentDetail.endDate}
            register={register}
            requiredField={false}
            error={errorKey?.endDate?.message}
          />

          <FormInputCurrency
            id={`${jobKey}.annualBasicSalary`}
            label={LABELS.employmentDetail.annualBasicSalary}
            register={register}
            requiredField={false}
            error={errorKey?.annualBasicSalary?.message}
            setValue={setValue}
          />

          <FormInputCurrency
            id={`${jobKey}.annualBonusGross`}
            label={LABELS.employmentDetail.annualBonusGross}
            register={register}
            requiredField={false}
            error={errorKey?.annualBonusGross?.message}
            setValue={setValue}
          />

          <FormInputCurrency
            id={`${jobKey}.annualOvertimeGross`}
            label={LABELS.employmentDetail.annualOvertimeGross}
            register={register}
            requiredField={false}
            error={errorKey?.annualOvertimeGross?.message}
            setValue={setValue}
          />

          <FormInputCurrency
            id={`${jobKey}.annualCommissionGross`}
            label={LABELS.employmentDetail.annualCommissionGross}
            register={register}
            requiredField={false}
            error={errorKey?.annualCommissionGross?.message}
            setValue={setValue}
          />

          <FormInputCurrency
            id={`${jobKey}.annualLocationOrCarAllowanceGross`}
            label={LABELS.employmentDetail.annualLocationOrCarAllowanceGross}
            register={register}
            requiredField={false}
            error={errorKey?.annualLocationOrCarAllowanceGross?.message}
            setValue={setValue}
          />
        </>
      )}

      {["SEMP", "SEMS"].includes(
        applicantsEmployment?.employmentStatus || "",
      ) && (
        <>
          <FormInputText
            id={`${jobKey}.occupation`}
            label={LABELS.employmentDetail.occupation}
            register={register}
            requiredField={false}
            error={errorKey?.occupation?.message}
          />

          <FormInputText
            id={`${jobKey}.businessName`}
            label={LABELS.employmentDetail.businessName}
            register={register}
            requiredField={false}
            error={errorKey?.businessName?.message}
          />

          <FormInputPercent
            id={`${jobKey}.percentBusinessOwned`}
            label={LABELS.employmentDetail.percentBusinessOwned}
            register={register}
            requiredField={false}
            error={errorKey?.percentBusinessOwned?.message}
          />

          <FormInputDate
            id={`${jobKey}.startDate`}
            label={LABELS.employmentDetail.startDate}
            register={register}
            requiredField={false}
            error={errorKey?.startDate?.message}
          />

          <FormInputCurrency
            id={`${jobKey}.latestYearNetProfit`}
            label={LABELS.employmentDetail.latestYearNetProfit}
            register={register}
            requiredField={false}
            error={errorKey?.latestYearNetProfit?.message}
            setValue={setValue}
            allowNegative
          />

          <FormInputCurrency
            id={`${jobKey}.previousYearNetProfit`}
            label={LABELS.employmentDetail.previousYearNetProfit}
            register={register}
            requiredField={false}
            error={errorKey?.previousYearNetProfit?.message}
            setValue={setValue}
            allowNegative
          />

          <FormInputText
            id={`${jobKey}.selfEmployedAccountant.firmName`}
            label={LABELS.employmentDetail.selfEmployedAccountant.firmName}
            register={register}
            requiredField={false}
            error={errorKey?.selfEmployedAccountant?.firmName?.message}
          />

          <FormInputText
            id={`${jobKey}.selfEmployedAccountant.phoneNumber`}
            label={LABELS.employmentDetail.selfEmployedAccountant.phoneNumber}
            register={register}
            requiredField={false}
            error={errorKey?.selfEmployedAccountant?.phoneNumber?.message}
          />

          <FormInputText
            id={`${jobKey}.selfEmployedAccountant.emailAddress`}
            label={LABELS.employmentDetail.selfEmployedAccountant.emailAddress}
            register={register}
            requiredField={false}
            error={errorKey?.selfEmployedAccountant?.emailAddress?.message}
          />

          <div className="flex flex-col space-y-4">
            <label className="block text-base">Accountants address</label>

            <AddressSetter
              clearErrors={clearErrors}
              setValue={setValue}
              register={register}
              idKey={`${jobKey}.selfEmployedAccountant`}
              errorKey={errorKey?.selfEmployedAccountant}
            />
          </div>
        </>
      )}

      {["DMDV", "DWDV"].includes(
        applicantsEmployment?.employmentStatus || "",
      ) && (
        <>
          <FormInputText
            id={`${jobKey}.occupation`}
            label={LABELS.employmentDetail.occupation}
            register={register}
            requiredField={false}
            error={errorKey?.occupation?.message}
          />

          <FormInputText
            id={`${jobKey}.employerName`}
            label={LABELS.employmentDetail.employerName}
            register={register}
            requiredField={false}
            error={errorKey?.employerName?.message}
          />

          <FormInputDate
            id={`${jobKey}.startDate`}
            label={LABELS.employmentDetail.startDate}
            register={register}
            requiredField={false}
            error={errorKey?.startDate?.message}
          />

          <FormInputCurrency
            id={`${jobKey}.projectedGrossProfitCurrentTradingYear`}
            label={
              LABELS.employmentDetail.projectedGrossProfitCurrentTradingYear
            }
            register={register}
            requiredField={false}
            error={errorKey?.projectedGrossProfitCurrentTradingYear?.message}
            setValue={setValue}
            allowNegative
          />

          <FormInputCurrency
            id={`${jobKey}.latestYearNetProfit`}
            label={LABELS.employmentDetail.latestYearNetProfit}
            register={register}
            requiredField={false}
            error={errorKey?.latestYearNetProfit?.message}
            setValue={setValue}
            allowNegative
          />

          <FormInputCurrency
            id={`${jobKey}.previousYearNetProfit`}
            label={LABELS.employmentDetail.previousYearNetProfit}
            register={register}
            requiredField={false}
            error={errorKey?.previousYearNetProfit?.message}
            setValue={setValue}
            allowNegative
          />

          <FormInputPercent
            id={`${jobKey}.shareholdingPercentInCompany`}
            label={LABELS.employmentDetail.shareholdingPercentInCompany}
            register={register}
            requiredField={false}
            error={errorKey?.shareholdingPercentInCompany?.message}
          />

          <FormInputCurrency
            id={`${jobKey}.salaryFromLatestFinalisedYear`}
            label={LABELS.employmentDetail.salaryFromLatestFinalisedYear}
            register={register}
            requiredField={false}
            error={errorKey?.salaryFromLatestFinalisedYear?.message}
            setValue={setValue}
          />

          <FormInputCurrency
            id={`${jobKey}.salaryFromPreviousYear`}
            label={LABELS.employmentDetail.salaryFromPreviousYear}
            register={register}
            requiredField={false}
            error={errorKey?.salaryFromPreviousYear?.message}
            setValue={setValue}
          />

          <FormInputCurrency
            id={`${jobKey}.currentYearProjectedDividends`}
            label={LABELS.employmentDetail.currentYearProjectedDividends}
            register={register}
            requiredField={false}
            error={errorKey?.currentYearProjectedDividends?.message}
            setValue={setValue}
          />

          <FormInputCurrency
            id={`${jobKey}.dividendFromLatestFinalisedYear`}
            label={LABELS.employmentDetail.dividendFromLatestFinalisedYear}
            register={register}
            requiredField={false}
            error={errorKey?.dividendFromLatestFinalisedYear?.message}
            setValue={setValue}
          />

          <FormInputCurrency
            id={`${jobKey}.dividendFromPreviousYear`}
            label={LABELS.employmentDetail.dividendFromPreviousYear}
            register={register}
            requiredField={false}
            error={errorKey?.dividendFromPreviousYear?.message}
            setValue={setValue}
          />
        </>
      )}
    </>
  );
};

export default JobEntry;
