import { gql } from "@apollo/client";

export const QUERY_DIP_COMPLETE = gql`
  query Dip($uuid: String!) {
    dip(uuid: $uuid) {
      uuid
      reference
      createdOn
      downloadUrl
      decision
      success
      case {
        uuid
        status
        reference
      }
    }
  }
`;

export const QUERY_DIP = gql`
  query Dip($uuid: String!) {
    dip(uuid: $uuid) {
      numberOfApplicants
      consentToCreditCheckAndInformationProcessing
      firstChargeLenderDipReference
      receivedSuccessfulDipFromFirstChargeLender
      loan {
        termOfInitialInterestRatePeriodYears
        termOfFirstChargeLoanYears
        termOfFirstChargeLoanMonths
        propertyPurchasePrice
        numberOfFinancialDependentsUnder17
        numberOfFinancialDependents
        isInterestRateFixedForAnInitialPeriod
        firstChargeSvrPercent
        firstChargeMortgageValue
        firstChargeInitialInterestRatePercent
        equityLoanValue
      }
      applicants {
        expenditures {
          schoolUniversityFees
          other
          futureChangeInExpenditure
          csaCourtOrderPayments
          creditProviders {
            repayFromMortgage
            providerType
            provider
            mortgagePriorPaidOff
            mortgageConvertedToB2l
            monthlyPayment
            creditEndDate
            balanceOutstanding
          }
          childcareCosts
          childMaintenancePayments
          changeInExpenditureDetail
          applicantHasOutstandingCredit
          additionalPropertyRunningCosts
        }
        employments {
          retirementIncomeSource
          numberOfJobs
          mortgageTermBeyondRetirementAge
          isRetired
          incomeChangesDetails
          hasOtherSourcesOfIncome
          employmentOtherIncomeSources {
            source
            annualGrossIncome
          }
          employmentDetail {
            treatedAsEmployedForTax
            startDate
            shareholdingPercentInCompany
            selfEmployedAccountant {
              townOrCity
              thoroughfare
              subBuildingNumber
              subBuildingName
              postcode
              phoneNumber
              firmName
              emailAddress
              county
              buildingNumber
              buildingName
              addressLine3
              addressLine2
              addressLine1
              address
            }
            salaryFromPreviousYear
            salaryFromLatestFinalisedYear
            projectedGrossProfitCurrentTradingYear
            previousYearNetProfit
            percentBusinessOwned
            occupation
            latestYearNetProfit
            endDate
            employmentType
            employmentStatus
            employerName
            dividendFromPreviousYear
            dividendFromLatestFinalisedYear
            dailyRate
            currentYearProjectedDividends
            businessName
            annualOvertimeGross
            annualLocationOrCarAllowanceGross
            annualCommissionGross
            annualBonusGross
            annualBasicSalary
          }
          employedInUk
          awareOfAnyChangesToIncome
          anticipatedRetirementAge
          anticipatedCurrentRetirementAnnualAmount
        }
        details {
          title
          surname
          previousName
          previousAddresses {
            townOrCity
            thoroughfare
            subBuildingNumber
            subBuildingName
            stayDurationYears
            stayDurationMonths
            residentialStatus
            postcode
            county
            buildingNumber
            buildingName
            addressLine3
            addressLine2
            addressLine1
            address
          }
          phoneNumber
          middleName
          forename
          email
          dob
          dateOfChange
          currentAddress {
            townOrCity
            thoroughfare
            subBuildingNumber
            subBuildingName
            stayDurationYears
            stayDurationMonths
            residentialStatus
            postcode
            county
            buildingNumber
            buildingName
            addressLine3
            addressLine2
            addressLine1
            address
          }
        }
      }
    }
  }
`;
