import { useLazyQuery } from "@apollo/client";
import { QUERY_ILLUSTRATION } from "api/queries/illustration";
import CTAButton from "components/CTA/Button";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "settings";
import useIllustrationStore from "store/Illustration";
import { Illustration } from "ts/interfaces/common/illustration";
import { renderDate } from "utils/helpers";

// Define the props interface
interface IllustrationsProps {
  caseId: string;
  illustrations: Illustration[]; // Array of illustration items
}

const Illustrations: React.FC<IllustrationsProps> = ({
  caseId,
  illustrations,
}) => {
  const navigate = useNavigate();

  const caseIllustrationUrl = `${APP_URLS.CREATE_ILLUSTRATION}?case=${caseId}`;

  const [loadingCreateNewIllustration, setLoadingCreateNewIllustration] =
    useState(false);
  const { setNewIllustrationFromExistingIllustration } = useIllustrationStore();

  const [
    getSubmittedIllustration,
    { data: submittedIllustrationData, error: getSubmittedIllustrationError },
  ] = useLazyQuery(QUERY_ILLUSTRATION, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (getSubmittedIllustrationError) {
      routeToEmptyIllustration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSubmittedIllustrationError]);

  useEffect(() => {
    if (submittedIllustrationData?.illustration) {
      setNewIllustrationFromExistingIllustration(
        submittedIllustrationData.illustration,
      );
      setTimeout(() => {
        navigate(caseIllustrationUrl);
      }, 500);
    }
  }, [
    submittedIllustrationData,
    setNewIllustrationFromExistingIllustration,
    caseIllustrationUrl,
    navigate,
  ]);

  const startNewIllustration = () => {
    setLoadingCreateNewIllustration(true);

    if (!illustrations.length) {
      return routeToEmptyIllustration();
    }

    const mostRecentIllustration = illustrations.reduce((latest, current) => {
      return new Date(current.createdOn) > new Date(latest.createdOn)
        ? current
        : latest;
    }, illustrations[0]);

    getSubmittedIllustration({
      variables: {
        uuid: mostRecentIllustration.uuid,
      },
    });
  };

  const routeToEmptyIllustration = () => {
    window.location.href = caseIllustrationUrl;
  };

  return (
    <>
      <div className="flex items-end justify-between">
        <h3 className="text-lg font-semibold">Illustrations</h3>
        <div className="space-x-2">
          {!loadingCreateNewIllustration && (
            <CTAButton
              label="Create new Illustration"
              onClick={routeToEmptyIllustration}
              secondary
              small
            />
          )}
          {illustrations.length > 0 && (
            <CTAButton
              label="Duplicate latest Illustration"
              onClick={startNewIllustration}
              loading={loadingCreateNewIllustration}
              secondary
              small
            />
          )}
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="overflow-hidden rounded-lg shadow ring-1 ring-black ring-opacity-5">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Reference
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Date created
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  <span className="sr-only">Action</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {illustrations.length ? (
                illustrations.map((ill, i) => (
                  <tr key={i}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {ill.reference}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {renderDate(ill.createdOn)}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <a
                        download
                        href={ill?.downloadUrl}
                        className="link underline"
                      >
                        Download
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={4}
                    className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    -
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Illustrations;
