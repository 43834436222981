import { FormInputs } from "pages/Illustration/types";
import { create } from "zustand";

interface FormStore {
  formData: FormInputs | Object;
  setNewIllustrationFromExistingIllustration: (
    data: Partial<FormInputs>,
  ) => void;
}

const useIllustrationStore = create<FormStore>((set, get) => ({
  formData: {},

  setNewIllustrationFromExistingIllustration: (data) =>
    set((state) => ({
      formData: {
        ...state.formData,
        ...data,
      },
    })),

  resetIllustration: () =>
    set({
      formData: {},
    }),
}));

export default useIllustrationStore;
