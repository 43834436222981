import { create } from "zustand";

import {
  COMPLETE_FORM_STATUS,
  DEFAULT_FORM_STATUS,
} from "pages/DecisionInPrinciple/defaultFormData";
import {
  FormSchema,
  FormSchemaCase,
  FormSchemaStatus,
} from "pages/DecisionInPrinciple/formSchema";
import {
  DEFAULT_STEP,
  getStepByKey,
  StepType,
} from "pages/DecisionInPrinciple/formSteps";
import {
  APPLICATION_COMPLETE_KEY,
  APPLICATION_IN_PROGRESS_KEY,
} from "utils/consts";

interface FormStore {
  formData: FormSchema;
  formStep: StepType;
  formStatus: FormSchemaStatus;
  dipCase: FormSchemaCase | null;
  isStartStepComplete: () => boolean;
  setFormStep: (stepKey: string) => void;
  setFormData: (data: Partial<FormSchema>) => void;
  setFormStatus: (stepKey: string, complete: boolean) => void;
  setDipCase: (caseDetail: any) => void;
  setNewDipFromExistingDip: (caseDetail: any, dipDetail: any) => void;
  resetDip: () => void;
}

const useDipStore = create<FormStore>((set, get) => ({
  formStep: DEFAULT_STEP,
  formStatus: DEFAULT_FORM_STATUS,
  formData: {
    start: {
      numberOfApplicants: 1,
    },
  },
  dipCase: null,

  isStartStepComplete: () =>
    get().formStatus["start"] === APPLICATION_COMPLETE_KEY,

  setFormStep: (stepKey) =>
    set({
      formStep: getStepByKey(stepKey),
    }),

  setFormData: (data) =>
    set((state) => ({
      formData: {
        ...state.formData,
        ...data,
      },
    })),

  setFormStatus: (stepKey, complete) =>
    set((state) => ({
      formStatus: {
        ...state.formStatus,
        ...{
          [stepKey]: complete
            ? APPLICATION_COMPLETE_KEY
            : APPLICATION_IN_PROGRESS_KEY,
        },
      },
    })),

  setDipCase: (caseDetail) =>
    set((state) => ({
      dipCase: caseDetail || state.dipCase,
    })),

  setNewDipFromExistingDip: (caseDetail, dipDetail) =>
    set((state) => {
      const {
        numberOfApplicants,
        consentToCreditCheckAndInformationProcessing,
        receivedSuccessfulDipFromFirstChargeLender,
        firstChargeLenderDipReference,
        ...restDipDetail
      } = dipDetail;

      return {
        dipCase: caseDetail || state.dipCase,
        formData: {
          ...restDipDetail,
          start: {
            numberOfApplicants: numberOfApplicants,
            consentToCreditCheckAndInformationProcessing:
              consentToCreditCheckAndInformationProcessing.toString(),
            receivedSuccessfulDipFromFirstChargeLender:
              receivedSuccessfulDipFromFirstChargeLender.toString(),
            firstChargeLenderDipReference: firstChargeLenderDipReference,
          },
        },
        formStatus: COMPLETE_FORM_STATUS,
      };
    }),

  resetDip: () =>
    set({
      formStep: DEFAULT_STEP,
      formStatus: DEFAULT_FORM_STATUS,
      dipCase: null,
      formData: {
        start: {
          numberOfApplicants: 1,
        },
      },
    }),
}));

export default useDipStore;
