import { CaseDocument } from "../types";

interface UploadedFileListProps {
  files: CaseDocument[];
  deletingId: string | null;
  onDelete: (id: string) => void;
}

const UploadedFileList: React.FC<UploadedFileListProps> = ({
  files,
  deletingId,
  onDelete,
}) => {
  return (
    <div className="mt-4">
      <h3 className="mb-2 text-sm font-medium">Uploaded files:</h3>
      <ul className="space-y-2">
        {files.map((file) => (
          <li
            key={file.uuid}
            className="group flex items-center justify-between rounded bg-gray-50 p-2 text-sm"
          >
            <div className="flex items-center">
              <svg
                className="mr-2 h-5 w-5 text-green-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={3}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span className="max-w-xs truncate">
                <a
                  download
                  href={file.downloadUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-brand-green-dark hover:text-brand-green-dark hover:underline"
                >
                  {file.displayName}
                </a>
              </span>
            </div>
            <button
              onClick={() => onDelete(file.uuid)}
              disabled={deletingId === file.uuid}
              className={`
                ml-2 rounded-full p-1
                ${
                  deletingId === file.uuid
                    ? "cursor-wait bg-gray-200"
                    : "bg-red-100 hover:bg-red-200"
                }
              `}
              title="Delete file"
            >
              {deletingId === file.uuid ? (
                <svg
                  className="h-4 w-4 animate-spin text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              ) : (
                <svg
                  className="h-4 w-4 text-red-800"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UploadedFileList;
