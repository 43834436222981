export const DEFAULT_CREDIT_PROVIDER_DATA = {
  providerType: "",
  provider: "",
  balanceOutstanding: "",
  monthlyPayment: "",
  creditEndDate: "",
  mortgagePriorPaidOff: null,
  repayFromMortgage: null,
  mortgageConvertedToB2l: null,
};

export const DEFAULT_EXPENDITURE_DATA = {
  futureChangeInExpenditure: undefined,
  changeInExpenditureDetail: "",
  childcareCosts: undefined,
  schoolUniversityFees: undefined,
  childMaintenancePayments: undefined,
  csaCourtOrderPayments: undefined,
  additionalPropertyRunningCosts: undefined,
  other: undefined,
  applicantHasOutstandingCredit: undefined,
  creditProviders: [],
};
