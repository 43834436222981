export const AHAUZ_PHONE_NUMBER = "07769 469660";

export const API_URL = process.env.REACT_APP_API as string;

export const APP_URLS = {
  ACCOUNT: {
    ACCEPT_INVITE: "/account/accept-invite/:token",
    ACCOUNT_PROFILE: "/account/profile",
    RESET_PASSWORD: "/account/reset-password/:uidb64/:token",
    FORGOT_PASSWORD: "/account/forgot-password",
  },
  APPLICATION_FORM: "/application-form",
  CASE_MANAGEMENT: "/cases",
  CASE_DETAIL: "/cases/:caseId",
  CASE_DOCUMENTS: "/cases/:caseId/documents",
  CREATE_ILLUSTRATION: "/illustration/create",
  DASHBOARD: "/",
  DECISION_IN_PRINCIPLE: "/dip",
  LOGIN: "/login",
  RESOURCES: "/resources",
};

export const WEB_URLS = {
  HOME: process.env.REACT_APP_WEB as string,
  DOCUMENT_SERVER: process.env.REACT_APP_DOCUMENT_SERVER as string,
};

export const COOKIES = {
  CSRF: "__Secure-csrftoken",
};
