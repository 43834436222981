import { gql } from "@apollo/client";

export const QUERY_ILLUSTRATION_COMPLETE = gql`
  query Illustration($uuid: String!) {
    illustration(uuid: $uuid) {
      uuid
      reference
      createdOn
      downloadUrl
      case {
        uuid
        status
        reference
      }
    }
  }
`;

export const QUERY_ILLUSTRATION = gql`
  query Illustration($uuid: String!) {
    illustration(uuid: $uuid) {
      applicant1FirstName
      applicant1LastName
      applicant1Title
      applicant2FirstName
      applicant2Title
      applicant2LastName
      creditIntermediaryFees
      equityLoanDurationMonths
      equityLoanDurationYears
      equityLoanValue
      propertyValue
    }
  }
`;
