import { gql } from "@apollo/client";

export const QUERY_APPLICATIONS = gql`
  query Applications($status: String, $selectLatest: Boolean, $offset: Int) {
    applications(
      status: $status
      selectLatest: $selectLatest
      pagination: { offset: $offset, limit: 10 }
    ) {
      totalCount
      pageInfo {
        limit
        offset
      }
      results {
        uuid
        reference
        createdOn
        status
        applicantNames
        case {
          uuid
          reference
        }
        broker {
          applicationDate
        }
        applicants {
          title
          forename
          surname
        }
      }
    }
  }
`;
