import { useQuery } from "@apollo/client";
import { useState } from "react";

import { QUERY_CASES } from "api/queries/cases";
import GenericError from "components/GenericError";
import PageContainer from "components/Page/Container";
import PageContent from "components/Page/Content";
import Pagination, { getPaginationOffset } from "components/Pagination";
import { Case } from "ts/interfaces/common/case";
import Loading from "../../components/Loading";
import CaseCreateButton from "./components/CaseCreateButton";
import CaseList from "./components/CaseList";
import FilterCase from "./components/FilterCase";

interface Option {
  value: string;
  title: string;
}

interface QueryCasesData {
  cases: {
    totalCount: number;
    results: Case[];
  };
  options_status: {
    options: Option[];
  };
}

const ITEMS_PER_PAGE = 20;

const Cases: React.FC = () => {
  const [createError, setCreateError] = useState<Error | null>(null);
  const [recentCase, setRecentCase] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState(1);

  const { loading, error, data, refetch } = useQuery<QueryCasesData>(
    QUERY_CASES,
    {
      fetchPolicy: "no-cache",
      variables: {
        offset: getPaginationOffset(currentPage, ITEMS_PER_PAGE),
      },
    },
  );

  const handleNewCaseCreated = (caseUUID: string) => {
    setCurrentPage(1);
    setRecentCase(caseUUID);
  };

  const renderContent = () => {
    if (!data || loading) {
      return (
        <PageContainer>
          <div className="text-center">
            <Loading />
            <p className="mt-4">Loading Cases...</p>
          </div>
        </PageContainer>
      );
    }
    if (error) {
      return <GenericError error={error} />;
    }
    if (createError) {
      return <GenericError error={createError} />;
    }
    return (
      <>
        <CaseCreateButton
          refetch={refetch}
          setCreateError={setCreateError}
          setRecentCase={handleNewCaseCreated}
        />
        <CaseList cases={data.cases.results} recentCase={recentCase} />
        <Pagination
          data={data.cases}
          itemsPerPage={ITEMS_PER_PAGE}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </>
    );
  };

  return (
    <PageContent title="Cases">
      <div className="grid md:grid-cols-4 md:gap-8">
        <FilterCase refetch={refetch} options={data?.options_status.options} />
        <div className="md:col-span-3">{renderContent()}</div>
      </div>
    </PageContent>
  );
};

export default Cases;
