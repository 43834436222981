import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { MUTATION_CREATE_DIP } from "api/mutations/dip";
import PageContent from "components/Page/Content";
import Sidebar from "components/Page/Sidebar";
import FormErrors from "pages/ApplicationForm/components/FormErrors";
import { APP_URLS } from "settings";
import useDipStore from "store/DecisionInPrinciple";
import { getApiSubmitData } from "./api";
import DipSidebar from "./components/DipSidebar";
import FormNav from "./components/FormNav";
import SubmitDipProgress from "./components/SubmitDipProgress";
import { DEFAULT_FORM_DATA } from "./defaultFormData";
import { FormSchema } from "./formSchema";
import { getStepByID, TOTAL_STEPS } from "./formSteps";
import { getValidationSchema } from "./validation";

const DipForm: React.FC = () => {
  const [submitError, setSubmitError] = useState(false);

  const navigate = useNavigate();

  const { step: urlStep } = useParams<{
    step: string;
  }>();

  const {
    formData,
    formStep,
    dipCase,
    isStartStepComplete,
    setFormData,
    setFormStep,
    setFormStatus,
    resetDip,
  } = useDipStore();

  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    clearErrors,
  } = useForm<FormSchema>({
    defaultValues: DEFAULT_FORM_DATA,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(getValidationSchema(formStep.key)),
  });

  const [
    submitDip,
    { data: submitDipData, loading: submitDipLoading, error: submitDipError },
  ] = useMutation(MUTATION_CREATE_DIP);

  const numberOfApplicants = useWatch({
    control,
    name: "start.numberOfApplicants",
    defaultValue: 1,
  });

  const {
    fields: applicantsFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "applicants",
  });

  // Watch number of applicants and update the applicants array dynamically
  useEffect(() => {
    const currentApplicants = applicantsFields.length;
    const targetApplicants = Number(numberOfApplicants);

    if (targetApplicants > currentApplicants) {
      // Append new applicants if the number is increased
      for (let i = currentApplicants; i <= targetApplicants; i++) {
        append(DEFAULT_FORM_DATA.applicants);
      }
    } else if (targetApplicants < currentApplicants) {
      // Remove extra applicants if the number is decreased
      for (let i = currentApplicants; i > targetApplicants; i--) {
        remove(i - 1);
      }
    }
  }, [numberOfApplicants, applicantsFields.length, append, remove]);

  useEffect(() => {
    if (errors && Object.keys(errors).length) {
      setFormStatus(formStep.key, false);
    }
  }, [errors, formStep, setFormStatus]);

  useEffect(() => {
    if (urlStep) {
      setFormStep(urlStep);
    }
  }, [urlStep, setFormStep]);

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  useEffect(() => {
    if (submitDipData) {
      setTimeout(() => {
        resetDip();
        reset(DEFAULT_FORM_DATA);
        navigate(
          `${APP_URLS.DECISION_IN_PRINCIPLE}/${submitDipData?.dip?.createDip?.uuid}/complete`,
        );
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitDipData, submitDipError, reset, resetDip, navigate]);

  const inSubmitState = submitDipData || submitDipLoading || submitDipError;
  const startStepComplete = formStep.key === "start" || isStartStepComplete();

  const onSubmit = async (data: FormSchema) => {
    setFormData(data);
    setFormStatus(formStep.key, true);

    const realtimeFormData = useDipStore.getState().formData;

    if (submitError) {
      setSubmitError(false);
    }

    window.scrollTo(0, 0);

    if (formStep.id === TOTAL_STEPS) {
      try {
        submitDip({
          variables: {
            data: getApiSubmitData(realtimeFormData),
            case: dipCase?.uuid,
          },
        });
      } catch (error) {
        Sentry.captureException(error);
        setSubmitError(true);
      }

      return;
    }

    const nextStep = getStepByID(formStep.id + 1).key;
    navigate(`${APP_URLS.DECISION_IN_PRINCIPLE}/${nextStep}`);
  };

  const handleSaveAndNavigate = async (navigateStep: string) => {
    setFormData(getValues());
    navigate(`${APP_URLS.DECISION_IN_PRINCIPLE}/${navigateStep}`);
  };

  const handlePrevious = async () => {
    const previousStep = getStepByID(formStep.id - 1).key;
    await handleSaveAndNavigate(previousStep);
  };

  const handleSidebarClick = async (stepKey: string) => {
    if (stepKey === formStep.key) {
      return;
    }
    await handleSaveAndNavigate(stepKey);
  };

  return (
    <PageContent title="Decision in Principle (DIP)">
      <div className="flex w-full flex-col md:flex-row md:space-x-8">
        {inSubmitState ? (
          <div className="mx-auto w-full max-w-3xl rounded-lg border border-gray-200 bg-white p-6 text-center md:p-8">
            <SubmitDipProgress
              submitDipError={submitDipError}
              dipCase={dipCase}
            />
          </div>
        ) : (
          <>
            <Sidebar title="New DIP">
              <DipSidebar onStepClick={handleSidebarClick} control={control} />
            </Sidebar>
            <div className="md:basis-3/4">
              <div className="rounded-lg border border-gray-200 bg-white p-6 md:p-8">
                {submitError && (
                  <FormErrors
                    formErrors={{
                      "Save error": {
                        message:
                          "We're unable to save your Application right now, please try again or contact us to discuss",
                      },
                    }}
                  />
                )}

                {startStepComplete ? (
                  <>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="flex flex-col space-y-2"
                      autoComplete="off"
                      noValidate
                    >
                      {formStep.component && (
                        <formStep.component
                          control={control}
                          clearErrors={clearErrors}
                          watch={watch}
                          register={register}
                          errors={errors}
                          applicantsFields={applicantsFields}
                          setValue={setValue}
                        />
                      )}

                      <FormNav handlePrevious={handlePrevious} />
                    </form>
                  </>
                ) : (
                  <div className="flex flex-col space-y-4 text-center">
                    <p>
                      Please first submit Applicant Details to complete this
                      step.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </PageContent>
  );
};

export default DipForm;
