import { useRef } from "react";

import CTAButton from "components/CTA/Button";

interface PaginationProps {
  data: {
    totalCount: number;
    results: any[];
  };
  itemsPerPage: number;
  currentPage: number;
  setCurrentPage: Function;
}

export const getPaginationOffset = (pageNumber: number, itemsPerPage: number) =>
  (pageNumber - 1) * itemsPerPage;

const Pagination: React.FC<PaginationProps> = ({
  data,
  itemsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const scrollRef = useRef<HTMLInputElement>(null);

  const totalCount = data.totalCount || 0;
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const startResult = (currentPage - 1) * itemsPerPage + 1;
  const endResult = Math.min(currentPage * itemsPerPage, totalCount);

  const executeScroll = () =>
    scrollRef?.current?.parentElement?.scrollIntoView();

  const handleNextPage = () => {
    executeScroll();
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
  };

  const handlePreviousPage = () => {
    executeScroll();
    const prevPage = currentPage - 1;
    setCurrentPage(prevPage);
  };

  if (totalPages > 1) {
    return (
      <nav
        aria-label="Pagination"
        className="flex items-center justify-between py-3"
        ref={scrollRef}
      >
        <div className="hidden sm:block">
          <p className="text-sm">
            Showing <span className="font-medium">{startResult}</span> to{" "}
            <span className="font-medium">{endResult}</span> of{" "}
            <span className="font-medium">{totalCount}</span> results
          </p>
        </div>
        <div className="flex flex-1 justify-between space-x-3 sm:justify-end">
          <CTAButton
            secondary
            small
            label="Previous"
            onClick={handlePreviousPage}
            enabled={currentPage > 1}
          />
          <CTAButton
            secondary
            small
            label="Next"
            onClick={handleNextPage}
            enabled={currentPage < totalPages}
          />
        </div>
      </nav>
    );
  }

  return <></>;
};

export default Pagination;
