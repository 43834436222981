import React, { useEffect } from "react";
import {
  Control,
  useFieldArray,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";

import {
  DEFAULT_EMPLOYMENT_DATA,
  DEFAULT_EMPLOYMENT_DETAIL_DATA,
} from "components/Application/Form/ApplicantEmployment/defaultData";
import FormInputCurrency from "components/Form/Input/Currency";
import FormInputNumber from "components/Form/Input/Number";
import FormInputRadio from "components/Form/Input/Radio";
import FormInputSelect from "components/Form/Input/Select";
import FormInputText from "components/Form/Input/Text";
import FormInputTextArea from "components/Form/Input/TextArea";
import { ComonObjectType } from "ts/types";
import { checkFalseyValue, checkTruthyValue } from "utils/validation";
import { LABELS } from "./labels";
import MainEmploymentJobs from "./MainEmploymentJobs";
import OtherIncomeSources from "./OtherIncomeSources";

interface FormProps {
  control: Control<any, any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  clearErrors: UseFormClearErrors<any>;
  idKey: string;
  errorKey: ComonObjectType | undefined;
}

const ApplicantEmploymentFormFields: React.FC<FormProps> = ({
  control,
  register,
  setValue,
  clearErrors,
  idKey,
  errorKey,
}) => {
  const watchEmployments = useWatch({ name: idKey, control: control });

  const numberOfJobs = useWatch({
    control,
    name: `${idKey}.numberOfJobs`,
  });

  const {
    fields: jobsFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `${idKey}.employmentDetail`,
  });

  useEffect(() => {
    const currentJobs = jobsFields.length;
    const targetJobs = Math.min(Number(numberOfJobs), 2);

    if (targetJobs > currentJobs) {
      for (let i = currentJobs; i < targetJobs; i++) {
        append(DEFAULT_EMPLOYMENT_DETAIL_DATA);
      }
    } else if (targetJobs < currentJobs) {
      for (let i = currentJobs; i > targetJobs; i--) {
        remove(i - 1);
      }
    }
  }, [numberOfJobs, jobsFields.length, append, remove]);

  // Reset core employment if isretired changes to true
  useEffect(() => {
    if (!watchEmployments) {
      return;
    }
    if (checkTruthyValue(watchEmployments.isRetired)) {
      setValue(`${idKey}`, DEFAULT_EMPLOYMENT_DATA);
      remove(); // Remove job fields
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchEmployments?.isRetired]);

  // Reset income sources if not hasOtherSourcesOfIncome
  useEffect(() => {
    if (!watchEmployments) {
      return;
    }
    if (
      !checkTruthyValue(watchEmployments.hasOtherSourcesOfIncome) &&
      (watchEmployments?.employmentOtherIncomeSources?.length || 0) > 0
    ) {
      setValue(`${idKey}.employmentOtherIncomeSources`, []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchEmployments?.hasOtherSourcesOfIncome]);

  return (
    <div className="flex flex-col space-y-4">
      <FormInputRadio
        id={`${idKey}.isRetired`}
        label={LABELS.isRetired}
        register={register}
        requiredField={false}
        initValue={watchEmployments?.isRetired}
        error={errorKey?.isRetired?.message}
      />

      {checkFalseyValue(watchEmployments?.isRetired) && (
        <>
          <FormInputRadio
            id={`${idKey}.employedInUk`}
            label={LABELS.employedInUk}
            register={register}
            requiredField={false}
            initValue={watchEmployments?.employedInUk}
            error={errorKey?.employedInUk?.message}
          />

          <FormInputSelect
            id={`${idKey}.numberOfJobs`}
            label={LABELS.numberOfJobs}
            register={register}
            initSelect
            options={[
              {
                value: 1,
                title: "1",
              },
              {
                value: 2,
                title: "2",
              },
            ]}
            error={errorKey?.numberOfJobs?.message}
          />

          <MainEmploymentJobs
            errorKey={errorKey}
            setValue={setValue}
            clearErrors={clearErrors}
            idKey={idKey}
            jobsFields={jobsFields}
            {...{ control, register }}
          />

          <label className="block text-base font-semibold">
            Other Employment information
          </label>

          <FormInputRadio
            id={`${idKey}.awareOfAnyChangesToIncome`}
            label={LABELS.awareOfAnyChangesToIncome}
            register={register}
            requiredField={false}
            initValue={watchEmployments?.awareOfAnyChangesToIncome}
            error={errorKey?.awareOfAnyChangesToIncome?.message}
          />

          {checkTruthyValue(watchEmployments?.awareOfAnyChangesToIncome) && (
            <FormInputTextArea
              id={`${idKey}.incomeChangesDetails`}
              label={LABELS.incomeChangesDetails}
              register={register}
              requiredField={false}
              error={errorKey?.incomeChangesDetails?.message}
            />
          )}

          <FormInputRadio
            id={`${idKey}.hasOtherSourcesOfIncome`}
            label={LABELS.hasOtherSourcesOfIncome}
            register={register}
            requiredField={false}
            initValue={watchEmployments?.hasOtherSourcesOfIncome}
            error={errorKey?.hasOtherSourcesOfIncome?.message}
          />

          {checkTruthyValue(watchEmployments?.hasOtherSourcesOfIncome) && (
            <OtherIncomeSources
              setValue={setValue}
              idKey={idKey}
              errorKey={errorKey}
              {...{ control, register }}
            />
          )}

          <FormInputNumber
            id={`${idKey}.anticipatedRetirementAge`}
            label={LABELS.anticipatedRetirementAge}
            register={register}
            requiredField={false}
            error={errorKey?.anticipatedRetirementAge?.message}
            maxDigits={3}
          />

          <FormInputRadio
            id={`${idKey}.mortgageTermBeyondRetirementAge`}
            label={LABELS.mortgageTermBeyondRetirementAge}
            register={register}
            requiredField={false}
            initValue={watchEmployments?.mortgageTermBeyondRetirementAge}
            error={errorKey?.mortgageTermBeyondRetirementAge?.message}
          />

          {checkTruthyValue(
            watchEmployments?.mortgageTermBeyondRetirementAge,
          ) && (
            <>
              <FormInputText
                id={`${idKey}.retirementIncomeSource`}
                label={LABELS.retirementIncomeSource}
                register={register}
                requiredField={false}
                error={errorKey?.retirementIncomeSource?.message}
              />

              <FormInputCurrency
                id={`${idKey}.anticipatedCurrentRetirementAnnualAmount`}
                label={LABELS.anticipatedCurrentRetirementAnnualAmount}
                register={register}
                requiredField={false}
                setValue={setValue}
                error={
                  errorKey?.anticipatedCurrentRetirementAnnualAmount?.message
                }
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ApplicantEmploymentFormFields;
