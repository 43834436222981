import { gql } from "@apollo/client";

export const QUERY_APPLICATION = gql`
  query Application($uuid: String!, $status: String) {
    application(uuid: $uuid, status: $status) {
      uuid
      reference
      modifiedOn
      case {
        reference
        uuid
      }
      numberOfApplicants
      broker {
        levelOfAdviceGiven
        confirmBrokerDeclarations
        isFeeCharged
        firmName
        feeAmount
        email
        applicationDate
        name
        phoneNumber
        status
      }
      applicants {
        uuid
        currentAddress {
          address
          addressLine1
          addressLine2
          addressLine3
          buildingName
          buildingNumber
          county
          postcode
          residentialStatus
          stayDurationMonths
          stayDurationYears
          subBuildingName
          subBuildingNumber
          thoroughfare
          townOrCity
        }
        dateOfChange
        dob
        dualNationality
        email
        visaType
        title
        surname
        status
        previousName
        previousAddresses {
          uuid
          townOrCity
          thoroughfare
          subBuildingNumber
          subBuildingName
          stayDurationYears
          stayDurationMonths
          residentialStatus
          postcode
          county
          buildingNumber
          buildingName
          addressLine3
          addressLine2
          addressLine1
          address
        }
        phoneNumber
        nationality
        maritalStatus
        middleName
        hasRefugeeAsylumStatus
        hasPermanentUkResideRight
        hasDiplomaticImmunity
        gender
        forename
      }
      declarations {
        applicantId
        ccjsGreaterThan500Last3years
        declarationComment
        defaultsGreaterThan10012monthsOr5003years
        hadCcj
        hadPropertyReprocessed
        hasBeenBankrupt
        hasDebtRegistered
        hasFailedCreditAgreements
        hasMadeCreditorsArrangement
        hasUnsatisfiedDefaults
        missed2OrMoreCreditPaymentsLast6months
        moreThanOneDefaultLast3years
        status
      }
      employments {
        applicantId
        status
        numberOfJobs
        isRetired
        incomeChangesDetails
        hasOtherSourcesOfIncome
        retirementIncomeSource
        employedInUk
        awareOfAnyChangesToIncome
        anticipatedRetirementAge
        mortgageTermBeyondRetirementAge
        anticipatedCurrentRetirementAnnualAmount
        employmentDetail {
          annualBasicSalary
          annualBonusGross
          annualCommissionGross
          annualLocationOrCarAllowanceGross
          annualOvertimeGross
          businessName
          currentYearProjectedDividends
          dailyRate
          dividendFromLatestFinalisedYear
          dividendFromPreviousYear
          employerName
          employmentType
          endDate
          uuid
          latestYearNetProfit
          occupation
          percentBusinessOwned
          previousYearNetProfit
          projectedGrossProfitCurrentTradingYear
          salaryFromLatestFinalisedYear
          salaryFromPreviousYear
          selfEmployedAccountant {
            townOrCity
            thoroughfare
            subBuildingNumber
            subBuildingName
            postcode
            phoneNumber
            firmName
            emailAddress
            county
            buildingNumber
            buildingName
            addressLine3
            addressLine2
            addressLine1
            address
          }
          shareholdingPercentInCompany
          startDate
          employmentStatus
          treatedAsEmployedForTax
        }
        employmentOtherIncomeSources {
          annualGrossIncome
          uuid
          source
        }
      }
      expenditures {
        applicantId
        additionalPropertyRunningCosts
        changeInExpenditureDetail
        childMaintenancePayments
        childcareCosts
        applicantHasOutstandingCredit
        creditProviders {
          balanceOutstanding
          creditEndDate
          uuid
          monthlyPayment
          mortgageConvertedToB2l
          mortgagePriorPaidOff
          provider
          providerType
          repayFromMortgage
        }
        csaCourtOrderPayments
        futureChangeInExpenditure
        other
        schoolUniversityFees
        status
      }
      loan {
        amountOfGift
        depositAmount
        equityLoanAmountRequired
        equityLoanRepaymentPlan
        firstChargeInitialInterestRatePercent
        firstChargeLender
        firstChargeMortgageValue
        valueOfFeeAddedToFirstChargeMortgage
        firstChargeSvrPercent
        gifterDetail
        isAllDepositByOwnResource
        isAllOrPartDepositAGift
        isFirstChargeARepaymentMortgage
        isGiftFromCurrentOwner
        isInterestRateFixedForAnInitialPeriod
        otherDepositAmount
        otherDepositSource
        otherRepaymentPlan
        otherSourceOfDeposit
        propertyPurchasePrice
        purposeOfMortgage
        sourceOfDeposit
        status
        termOfFirstChargeLoanYears
        termOfFirstChargeLoanMonths
        termOfInitialInterestRatePeriodYears
        willGifterLiveInPropertyAfterPurchase
        willGifterTakeInterestInPropertyForGift
      }
      property {
        yearsLeftOnLease
        yearPropertyBuilt
        willBePrimaryResidence
        willBePartiallyRented
        useOfOutbuildingOrAnnexDetails
        townOrCity
        thoroughfare
        tenure
        subBuildingNumber
        subBuildingName
        status
        serviceChargeIfFlat
        propertyType
        propertyNearBusinessPremises
        postcode
        noOfFloorsIfFlat
        noOfBedrooms
        nameOfBuilder
        localAuthority
        isStudioFlat
        isNewBuildPurchase
        isCouncilFlat
        hasOutbuildingOrAnnex
        hasNhbcFnd15Cover
        groundRentIfFlat
        flatFloor
        county
        councilTaxMonthly
        councilTaxBand
        canUseWithoutStructuralChanges
        buildingNumber
        buildingName
        addressLine3
        addressLine2
        addressLine1
        address
      }
      other {
        anyResidentsFinanciallyDependentOnApplicant
        anyResidentsMemberOfApplicantsFamily
        anyResidentsProvidingFundsTowardsTransaction
        dependentsUnder17LivingAtProperty
        howManyResidentsFinanciallyDependentOnApplicant
        isPurchasePriceLessThanMarketValue
        isThisPrivateSale
        isVendorRelatedToApplicant
        numberOfFinancialDependents
        numberOfFinancialDependentsOver17
        numberOfFinancialDependentsUnder17
        otherResidentsDetail {
          dob
          uuid
          name
          relationship
        }
        othersLivingInPropertyBesideApplicant
        vacantPossessionOnCompletion
        status
      }
      solicitor {
        actingSolicitorName
        address
        addressLine1
        addressLine2
        addressLine3
        buildingName
        county
        buildingNumber
        emailAddress
        phoneNumber
        firmName
        postcode
        reference
        status
        subBuildingName
        subBuildingNumber
        thoroughfare
        townOrCity
      }
      confirm {
        status
      }
    }
  }
`;
