import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import { MUTATION_LOGIN } from "api/mutations/account";
import AlertError from "components/Alert/Error";
import AlertSuccess from "components/Alert/Success";
import CTAButton from "components/CTA/Button";
import FormInputEmail from "components/Form/Input/Email";
import FormInputPassword from "components/Form/Input/Password";
import Loading from "components/Loading";
import PageContainer from "components/Page/Container";
import PageDialogScreen from "components/Page/DialogScreen";
import { AHAUZ_PHONE_NUMBER, APP_URLS } from "settings";
import { VALIDATION_EMAIL, VALIDATION_REQUIRED } from "utils/validation";

interface FormInputs {
  email: string;
  password: string;
}

const schema = yup.object({
  email: yup.string().email(VALIDATION_EMAIL).required(VALIDATION_REQUIRED),
  password: yup.string().required(VALIDATION_REQUIRED),
});

export default function Login() {
  const [formError, setFormError] = useState<boolean>(false);

  const [
    login,
    {
      loading: mutationLoading,
      error: mutationError,
      data: mutationData,
      reset: resetMutation,
    },
  ] = useMutation(MUTATION_LOGIN);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const email = watch("email");
  const password = watch("password");

  useEffect(() => {
    if (mutationData?.accountLogin) {
      setTimeout(() => (window.location.href = APP_URLS.DASHBOARD), 2000);
      return;
    }
    setFormError(
      mutationError || (mutationData && !mutationData?.accountLogin),
    );
  }, [mutationError, mutationData]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (formError) {
      setFormError(false);
    }
  };

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    resetMutation();
    setFormError(false);

    login({
      variables: {
        username: data.email,
        password: data.password,
      },
    });
  };

  const renderCreateAccountSuccess = () => (
    <AlertSuccess title="Login success!" shadow>
      <p>
        We'll now redirect you to your{" "}
        <a
          href={APP_URLS.DASHBOARD}
          className="text-green-800 underline hover:text-green-800"
        >
          Dashboard
        </a>
        .
      </p>
    </AlertSuccess>
  );

  const renderLogin = () => {
    if (mutationData?.accountLogin) {
      return renderCreateAccountSuccess();
    }

    return (
      <PageContainer>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="space-y-4">
            <FormInputEmail
              id="email"
              label="Email"
              register={register}
              onChange={handleInputChange}
              error={errors?.email?.message}
            />
            <FormInputPassword
              id="password"
              label="Password"
              register={register}
              onChange={handleInputChange}
              error={errors?.password?.message}
              validate={false}
            />
            {formError && (
              <div className="pt-2">
                <AlertError title="Can not login">
                  <p>
                    Invalid email or password, please try again or contact our
                    Support team on {AHAUZ_PHONE_NUMBER}.
                  </p>
                </AlertError>
              </div>
            )}
            <div className="pt-2">
              {mutationLoading ? (
                <Loading />
              ) : (
                <CTAButton
                  type="submit"
                  enabled={!!(email && password)}
                  label="Login"
                  full
                />
              )}
            </div>
          </div>
        </form>
      </PageContainer>
    );
  };

  return (
    <PageDialogScreen
      title="Ahauz Intermediary Login"
      copy={
        <>
          Forgot your password?{" "}
          <a href={APP_URLS.ACCOUNT.FORGOT_PASSWORD}>Click here to reset it</a>
        </>
      }
    >
      <>
        {renderLogin()}
        <div className="mt-8 text-center text-base text-brand-copy-light">
          To become an Ahauz Intermediary, please reach out to us at:{" "}
          <a
            href="mailto:intermediaries@ahauz.com"
            title="intermediaries@ahauz.com"
          >
            intermediaries@ahauz.com
          </a>
        </div>
      </>
    </PageDialogScreen>
  );
}
