import React from "react";

import Loading from "components/Loading";
import { FormSchemaCase } from "../formSchema";
import DipDecisionFail from "./DipDecisionFail";

interface SubmitProgressProps {
  submitDipError: any;
  dipCase?: FormSchemaCase | null;
}

const SubmitDipProgress: React.FC<SubmitProgressProps> = ({
  submitDipError,
  dipCase,
}) => {
  if (submitDipError) {
    return <DipDecisionFail dipCase={dipCase} />;
  }

  return (
    <>
      <Loading />
      <p className="mt-4">Submitting Decision in Principle...</p>
    </>
  );
};

export default SubmitDipProgress;
