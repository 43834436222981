import { gql } from "@apollo/client";

export const QUERY_CASES = gql`
  query cases($search: String, $status: String, $offset: Int) {
    cases(
      search: $search
      status: $status
      pagination: { offset: $offset, limit: 20 }
    ) {
      totalCount
      pageInfo {
        limit
        offset
      }
      results {
        uuid
        reference
        status
        statusDisplay
        createdOn
        applicantNames
      }
    }
    options_status: __type(name: "CaseStatusType") {
      options: enumValues {
        value: name
        title: description
      }
    }
  }
`;

export const QUERY_CASE_DETAIL = gql`
  query case($uuid: UUID!) {
    case(uuid: $uuid) {
      uuid
      reference
      status
      statusDisplay
      createdOn
      applicantNames
      applications {
        uuid
        reference
        status
        statusDisplay
        createdOn
        broker {
          applicationDate
        }
        downloadUrl
        isLatestSubmission
      }
      illustrations {
        uuid
        reference
        createdOn
        downloadUrl
      }
      dips {
        uuid
        reference
        createdOn
        downloadUrl
        decisionDisplay
        success
      }
    }
  }
`;

export const QUERY_CASE_DOCUMENTS = gql`
  query CaseDocuments($caseId: UUID!) {
    case: caseDocuments(uuid: $caseId) {
      reference
      uuid
      status
      documents {
        uuid
        displayName
        documentType
        uploadedAt
        downloadUrl
      }
      application: latestApplication {
        uuid
        reference
        applicationDate
      }
    }
  }
`;
