import { useLazyQuery } from "@apollo/client";
import { QUERY_APPLICATION } from "api/queries/application";
import FormTitle from "components/Form/Title";
import Loading from "components/Loading";
import PageContent from "components/Page/Content";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getCaseDocumentsUrl } from "routes/links";
import { APP_URLS } from "settings";
import { APPLICATION_COMPLETE_KEY } from "utils/consts";
import GetApplicationProgress from "./components/GetApplicationProgress";

const ApplicationComplete: React.FC = () => {
  const { applicationId: urlApplicationId } = useParams<{
    applicationId: string;
  }>();

  const [application, setApplication] = useState<any | null>(null);

  const [
    getApplication,
    {
      data: backendApplication,
      loading: getApplicationLoading,
      error: getApplicationError,
    },
  ] = useLazyQuery(QUERY_APPLICATION, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (urlApplicationId) {
      getApplication({
        variables: {
          uuid: urlApplicationId,
          status: APPLICATION_COMPLETE_KEY,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlApplicationId, getApplication]);

  useEffect(() => {
    if (!backendApplication) {
      return;
    }
    setApplication(backendApplication);
  }, [backendApplication, setApplication]);

  if (getApplicationLoading || getApplicationError) {
    return (
      <GetApplicationProgress getApplicationLoading={getApplicationLoading} />
    );
  }

  return (
    <PageContent title="Application Form">
      <div className="mx-auto max-w-3xl space-y-5">
        <div className="rounded-lg border border-gray-200 bg-white p-6 md:p-8">
          {getApplicationLoading && (
            <>
              <Loading />
              <p className="mt-4 text-center">Submitting Application...</p>
            </>
          )}

          <div className="space-y-4">
            {getApplicationError && (
              <>
                <FormTitle
                  left
                  border
                  title="The Application has not been submitted"
                  mb={false}
                />
                <div className="space-y-2">
                  <p>
                    Sorry, there has been an error submitting your Application.
                  </p>
                  <p className="mt-2">
                    Please contact us to discuss this further.
                  </p>
                </div>
              </>
            )}

            {application && (
              <>
                <FormTitle
                  left
                  border
                  title="The Application has been submitted"
                  mb={false}
                />
                <div className="space-y-2">
                  <p>
                    Application successfully submitted, we&apos;ll be in touch
                    shortly.
                  </p>
                  <p>
                    Please submit the supporting Application documents{" "}
                    <a
                      className="link underline"
                      href={getCaseDocumentsUrl(
                        application.application.case.uuid,
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>
                    .
                  </p>
                  {application?.application?.case?.uuid && (
                    <p>
                      You can track your Case (
                      {application.application.case.reference}) attached to this
                      Application{" "}
                      <Link
                        to={`${APP_URLS.DASHBOARD}cases/${application.application.case.uuid}`}
                        className="underline"
                      >
                        here
                      </Link>
                      .
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default ApplicationComplete;
