import { gql } from "@apollo/client";

export const MUTATION_CREATE_CASE = gql`
  mutation {
    case {
      caseCreate {
        case {
          uuid
          reference
          status
          createdOn
          applications {
            reference
            createdOn
            status
          }
          illustrations {
            uuid
            reference
            createdOn
          }
        }
        ok
      }
    }
  }
`;

export const MUTATION_DELETE_CASE = gql`
  mutation ($case: UUID!) {
    case {
      caseDelete(case: $case) {
        ok
        errors
      }
    }
  }
`;

export const MUTATION_UPLOAD_CASE_DOCUMENTS = gql`
  mutation UploadCaseDocuments($caseId: String!, $data: [CaseDocumentInput!]!) {
    case {
      data: uploadCaseDocuments(caseId: $caseId, data: $data) {
        uuid
        documentType
        uploadedAt
      }
    }
  }
`;

export const MUTATION_DELETE_CASE_DOCUMENT = gql`
  mutation DeleteCaseDocument($id: String!) {
    case {
      deleteCaseDocument(id: $id)
    }
  }
`;
