import { CaseType } from "pages/Illustration/types";

export enum DocumentType {
  CUSTOMER_DECLARATION = "CUSTOMER_DECLARATION",
  RESERVATION_FORM = "RESERVATION_FORM",
  INCOME_PROOF = "INCOME_PROOF",
  EXPENDITURE_DETAILS = "EXPENDITURE_DETAILS",
  PROOF_OF_DEPOSIT = "PROOF_OF_DEPOSIT",
  INTEREST_ONLY_DECLARATION = "INTEREST_ONLY_DECLARATION",
  INTEREST_ONLY_SALE_OF_MAIN_RESIDENCE_CUSTOMER_DECLARATION = "INTEREST_ONLY_SALE_OF_MAIN_RESIDENCE_CUSTOMER_DECLARATION",
  FIRST_CHARGE_MORTGAGE_OFFER = "FIRST_CHARGE_MORTGAGE_OFFER",
  GIFT_DEPOSIT_DECLARATION = "GIFT_DEPOSIT_DECLARATION",
  OCCUPIERS_CONSENT_FORM = "OCCUPIERS_CONSENT_FORM",
}

export interface ApplicationType {
  uuid: string;
  reference: string;
  modifiedOn: string;
  applicationDate: string;
}

export interface CaseDocument {
  uuid: string;
  displayName: string;
  documentType: DocumentType;
  uploadedAt: string;
  case: CaseType;
  downloadUrl: string;
}

export interface DocumentUploadType {
  type:
    | DocumentType.CUSTOMER_DECLARATION
    | DocumentType.RESERVATION_FORM
    | DocumentType.INCOME_PROOF
    | DocumentType.EXPENDITURE_DETAILS
    | DocumentType.PROOF_OF_DEPOSIT
    | DocumentType.INTEREST_ONLY_DECLARATION
    | DocumentType.INTEREST_ONLY_SALE_OF_MAIN_RESIDENCE_CUSTOMER_DECLARATION
    | DocumentType.FIRST_CHARGE_MORTGAGE_OFFER
    | DocumentType.GIFT_DEPOSIT_DECLARATION
    | DocumentType.OCCUPIERS_CONSENT_FORM;
  label: string;
  description: string;
  maxFiles: number;
}

export type DocumentUploadStateType = {
  uploading: string | null;
  uploadError: Record<string, string | null>;
  deleteError: Record<string, string | null>;
  deletingId: string | null;
  documentsError: unknown;
};
