import AccountProfile from "pages/Account/Profile";
import MultiStepForm from "pages/ApplicationForm";
import ApplicationComplete from "pages/ApplicationForm/ApplicationComplete";
import CaseDetail from "pages/CaseDetail";
import CaseDocuments from "pages/CaseDocuments";
import Cases from "pages/Cases";
import Dashboard from "pages/Dashboard";
import DecisionInPrinciple from "pages/DecisionInPrinciple";
import DipComplete from "pages/DecisionInPrinciple/DipComplete";
import CreateIllustration from "pages/Illustration";
import IllustrationComplete from "pages/Illustration/IllustrationComplete";
import Resources from "pages/Resources";
import { RouteType } from "routes/types";
import { APP_URLS } from "settings";

const AUTHENTICATED_ROUTES: RouteType[] = [
  {
    path: APP_URLS.DASHBOARD,
    component: Dashboard,
  },
  {
    path: APP_URLS.ACCOUNT.ACCOUNT_PROFILE,
    component: AccountProfile,
  },
  {
    path: `${APP_URLS.APPLICATION_FORM}/:applicationId/complete`,
    component: ApplicationComplete,
  },
  {
    path: `${APP_URLS.APPLICATION_FORM}/:applicationId/:step`,
    component: MultiStepForm,
  },
  {
    path: `${APP_URLS.APPLICATION_FORM}/:step`,
    component: MultiStepForm,
  },
  {
    path: `${APP_URLS.APPLICATION_FORM}/`,
    component: MultiStepForm,
  },
  {
    path: `${APP_URLS.CASE_MANAGEMENT}`,
    component: Cases,
  },
  {
    path: `${APP_URLS.CASE_DETAIL}`,
    component: CaseDetail,
  },
  {
    path: `${APP_URLS.CASE_DOCUMENTS}/`,
    component: CaseDocuments,
  },
  {
    path: `${APP_URLS.CREATE_ILLUSTRATION}/:illustrationId/complete`,
    component: IllustrationComplete,
  },
  {
    path: `${APP_URLS.CREATE_ILLUSTRATION}`,
    component: CreateIllustration,
  },
  {
    path: `${APP_URLS.DECISION_IN_PRINCIPLE}/:dipId/complete`,
    component: DipComplete,
  },
  {
    path: `${APP_URLS.DECISION_IN_PRINCIPLE}/:step`,
    component: DecisionInPrinciple,
  },
  {
    path: `${APP_URLS.DECISION_IN_PRINCIPLE}`,
    component: DecisionInPrinciple,
  },
  {
    path: `${APP_URLS.RESOURCES}/:resourceType`,
    component: Resources,
  },
  {
    path: `${APP_URLS.RESOURCES}`,
    component: Resources,
  },
];

export default AUTHENTICATED_ROUTES;
