import { useLazyQuery } from "@apollo/client";
import { QUERY_DIP } from "api/queries/dip";
import CTAButton from "components/CTA/Button";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "settings";
import useDipStore from "store/DecisionInPrinciple";
import { renderDate } from "utils/helpers";

interface DipCase {
  reference: string;
  uuid: string;
}

interface DipsProps {
  dips: any[];
  dipCase: DipCase;
}

const Dips: React.FC<DipsProps> = ({ dipCase, dips }) => {
  const navigate = useNavigate();

  const [loadingCreateNewDip, setLoadingCreateNewDip] = useState(false);
  const { setDipCase, setNewDipFromExistingDip } = useDipStore();

  const [
    getSubmittedDip,
    { data: submittedDipData, error: getSubmittedDipError },
  ] = useLazyQuery(QUERY_DIP, { fetchPolicy: "no-cache" });

  /**
   * If get dip data error, just create new dip without
   * pre-filling and navigate to form
   */
  useEffect(() => {
    if (getSubmittedDipError) {
      navigateToNewEmptyDip();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSubmittedDipError]);

  /**
   * With the queried DIP, set the new DIP store data and navigate the pre-populated form
   */
  useEffect(() => {
    if (submittedDipData?.dip) {
      setNewDipFromExistingDip(
        {
          reference: dipCase.reference,
          uuid: dipCase.uuid,
        },
        submittedDipData.dip,
      );
      // Force wait for store to sync
      setTimeout(() => {
        navigate(APP_URLS.DECISION_IN_PRINCIPLE);
      }, 500);
    }
  }, [submittedDipData, setNewDipFromExistingDip, dipCase, navigate]);

  /**
   * Get the most recent DIP for pre-populating the DIP form for a new submission, proceed to query the DIP
   */
  const startNewDip = () => {
    setLoadingCreateNewDip(true);

    if (!dips.length) {
      return navigateToNewEmptyDip();
    }

    const mostRecentDip = dips.reduce((latest, current) => {
      return new Date(current.createdOn) > new Date(latest.createdOn)
        ? current
        : latest;
    }, dips[0]);

    getSubmittedDip({
      variables: {
        uuid: mostRecentDip.uuid,
      },
    });
  };

  const navigateToNewEmptyDip = () => {
    setDipCase({
      reference: dipCase.reference,
      uuid: dipCase.uuid,
    });
    navigate(APP_URLS.DECISION_IN_PRINCIPLE);
  };

  return (
    <>
      <div className="flex items-end justify-between">
        <h3 className="text-lg font-semibold">Decision in Principle</h3>
        <div className="space-x-2">
          {!loadingCreateNewDip && (
            <CTAButton
              label="Create new DIP"
              onClick={navigateToNewEmptyDip}
              secondary
              small
            />
          )}
          {dips.length > 0 && (
            <CTAButton
              label="Duplicate latest DIP"
              onClick={startNewDip}
              loading={loadingCreateNewDip}
              secondary
              small
            />
          )}
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="overflow-hidden rounded-lg shadow ring-1 ring-black ring-opacity-5">
          <table className="w-full table-auto divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Reference
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Date created
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Decision
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  <span className="sr-only">Action</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {dips.length ? (
                dips.map((dip, i) => (
                  <tr key={i}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {dip.reference}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {renderDate(dip.createdOn)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {dip.decisionDisplay}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      {dip.success && (
                        <a
                          download
                          href={dip?.downloadUrl}
                          className="link underline"
                        >
                          Download
                        </a>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={4}
                    className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    -
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Dips;
