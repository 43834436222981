import moment from "moment";

import LogoWhite from "components/Image/Logo/White";

export default function Footer() {
  return (
    <footer className="brand-hero">
      <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8 lg:py-12">
        <div className="grid grid-cols-1 lg:grid-cols-5">
          <div className="col-span-4">
            <a href="/" className="hidden lg:block">
              <LogoWhite />
            </a>

            <p className="mt-10 text-center text-base text-white sm:mt-6 lg:text-left">
              Ahauz Finance Limited is authorised and regulated by the Financial
              Conduct Authority (Firm reference number 846554).
            </p>

            <p className="mt-6 text-center text-base text-white lg:text-left">
              Copyright © {moment().year()} Ahauz Finance Limited. All rights
              reserved.
            </p>

            <div className="mt-8 text-center text-white lg:mt-16 lg:text-left">
              <p className="text-sm">
                Ahauz is the trading name of Ahauz Finance Limited, a company
                registered in England and Wales{" "}
                <br className="block lg:hidden" />
                (Reference number: 11835452);
              </p>
              <p className="mt-1 text-sm">
                Registered office: 10 Orange St, Haymarket, London, WC2H 7DQ.
              </p>
            </div>
          </div>

          <div className="order-first col-span-1 flex flex-col justify-between text-center lg:order-1 lg:text-right">
            <a href="/" className="m-auto mb-10 block lg:mb-0 lg:hidden">
              <LogoWhite />
            </a>

            <div>
              <h3 className="text-center text-base font-semibold tracking-wide text-white lg:text-right">
                Useful links
              </h3>
              <nav>
                <ul className="mt-2 space-y-1 lg:mt-3">
                  <li>
                    <a
                      href="https://ahauz.com/contact"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="text-white hover:text-brand-orange-medium">
                        Contact
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ahauz.com/intermediary-terms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="text-white hover:text-brand-orange-medium">
                        Terms &amp; Conditions
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ahauz.com/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="text-white hover:text-brand-orange-medium">
                        Privacy Policy
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ahauz.com/cookie-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="text-white hover:text-brand-orange-medium">
                        Cookie Policy
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ahauz.com/complaints"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="text-white hover:text-brand-orange-medium">
                        Complaints
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
